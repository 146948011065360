import { useQuery, useQueryClient } from "@tanstack/react-query"
import BaseModal from "../../../components/layout/BaseModal"
import { Customer } from "../types/Types"
import { useContext, useState } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { useDeleteAdministratorMutation } from "../api/services"
import { preventKeyDown, validateEmail } from "../../../services/functions"
import AddCustomerModalScreen1 from "./addCustomerModal/AddCustomerModalScreen1"
import { getAllAdministrator } from "../api/apiCalls"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import { usePostDataMutation } from "../../api/services"

type AddCustomerModalType = {
    queryKey: string
}

export default function AddCustomerModal(props : AddCustomerModalType) {
    const { data, error, isLoading } = useQuery({
        queryKey: ["administratorData"],
        queryFn: getAllAdministrator,
    })

    const [screen2, setscreen2] = useState(false)
    const [inputAdmin, setinputAdmin] = useState(false)
    const [cname, setcname] = useState("")
    const [ccomment, setccomment] = useState("")
    const [cperson, setcperson] = useState("")
    const [cmail, setcmail] = useState("")
    const [ctel, setctel] = useState("")
    const [cadress_street, setcadress_street] = useState("")
    const [cadress_nr, setcadress_nr] = useState("")
    const [cadress_city, setcadress_city] = useState("")
    const [cadress_plz, setcadress_plz] = useState("")
    const [choosenAdmin, setchoosenAdmin] = useState()

    const [aname, setaname] = useState("")
    const [acomment, setacomment] = useState("")
    const [aperson, setaperson] = useState("")
    const [amail, setamail] = useState("")
    const [atel, setatel] = useState("")
    const [aadress_street, setaadress_street] = useState("")
    const [aadress_nr, setaadress_nr] = useState("")
    const [aadress_city, setaadress_city] = useState("")
    const [aadress_plz, setaadress_plz] = useState("")

    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)

    // clears input after adding a new customer
    const clearInput = () => {
        setcname("")
        setctel("")
        setccomment("")
        setcperson("")
        setcmail("")
        setcadress_street("")
        setcadress_nr("")
        setcadress_city("")
        setcadress_plz("")

        setaname("")
        setatel("")
        setacomment("")
        setaperson("")
        setamail("")
        setaadress_street("")
        setaadress_nr("")
        setaadress_city("")
        setaadress_plz("")
        setchoosenAdmin(undefined)
        setinputAdmin(false)
        setscreen2(false)
    }

    // ADD Functions
    const { mutate: addCustomer } = usePostDataMutation(queryClient, modalCon, "Kunde", props.queryKey, clearInput)
    const handleNewCustomer = () => {
        const newCustomerData: Customer = {
            customer: {
                name: cname,
                person: cperson,
                tel: ctel,
                mail: cmail,
                comment: ccomment,
                adress: {
                    street: cadress_street,
                    nr: cadress_nr,
                    plz: cadress_plz,
                    city: cadress_city,
                },
            },
            administrator: {
                name: aname,
                person: aperson,
                tel: atel,
                mail: amail,
                comment: acomment,
                adress: {
                    street: aadress_street,
                    nr: aadress_nr,
                    plz: aadress_plz,
                    city: aadress_city,
                },
            },
        }
        if (choosenAdmin) {
            newCustomerData.administrator = data.filter((item: any) => item._id === choosenAdmin)[0]
        }
        addCustomer({ body: newCustomerData, pageType: "customer" })
    }

    // delete Administrator
    const [deleteAdminScreen, setdeleteAdminScreen] = useState<boolean>(false)
    const { mutate: deleteAdministratorFunction } = useDeleteAdministratorMutation(queryClient, setdeleteAdminScreen, () =>
        setchoosenAdmin(undefined)
    )
    const deleteAdministrator = () => {
        console.log(choosenAdmin)
        deleteAdministratorFunction(choosenAdmin ?? "")
    }

    return (
        <>
            {error && <ErrorPage />}
            {isLoading && <Loading />}
            {data && !error && !isLoading && (
                <BaseModal
                    title={"Kunden hinzufügen - " + (screen2 ? "Verwaltung" : "Besitzer")}
                    modalId={1}
                    bottomRow={
                        <div className="flex justify-between">
                            <button
                                onKeyDown={() => preventKeyDown}
                                disabled={!screen2}
                                className="underline text-sm"
                                onClick={() => setscreen2(false)}>
                                zurück
                            </button>
                            {!screen2 ? (
                                <button
                                    onKeyDown={() => preventKeyDown}
                                    className="btn bg-blue-300 border-none hover:bg-blue-400"
                                    disabled={
                                        !validateEmail(cmail) ||
                                        cname === "" ||
                                        cmail === "" ||
                                        cadress_city === "" ||
                                        cadress_nr === "" ||
                                        cadress_plz === "" ||
                                        cadress_street === "" ||
                                        ctel === ""
                                    }
                                    onClick={() => {
                                        setscreen2(true)
                                    }}>
                                    Weiter
                                </button>
                            ) : (
                                <button
                                    onClick={() => {
                                        handleNewCustomer()
                                    }}
                                    className="btn btn-success"
                                    disabled={
                                        (!validateEmail(amail) ||
                                            aname === "" ||
                                            atel === "" ||
                                            amail === "" ||
                                            aadress_street === "" ||
                                            aadress_nr === "" ||
                                            aadress_city === "" ||
                                            aadress_plz === "") &&
                                        (choosenAdmin === "" || choosenAdmin === "Bitte Auswählen")
                                    }>
                                    Speichern
                                </button>
                            )}
                        </div>
                    }>
                    {!screen2 ? (
                        <AddCustomerModalScreen1
                            name={cname}
                            tel={ctel}
                            mail={cmail}
                            person={cperson}
                            comment={ccomment}
                            adress_street={cadress_street}
                            adress_nr={cadress_nr}
                            adress_plz={cadress_plz}
                            adress_city={cadress_city}
                            setname={setcname}
                            settel={setctel}
                            setmail={setcmail}
                            setperson={setcperson}
                            setcomment={setccomment}
                            setadress_street={setcadress_street}
                            setadress_nr={setcadress_nr}
                            setadress_plz={setcadress_plz}
                            setadress_city={setcadress_city}
                        />
                    ) : (
                        <AddCustomerModalScreen1
                            name={aname}
                            tel={atel}
                            mail={amail}
                            person={aperson}
                            comment={acomment}
                            adress_street={aadress_street}
                            adress_nr={aadress_nr}
                            adress_plz={aadress_plz}
                            adress_city={aadress_city}
                            setname={setaname}
                            settel={setatel}
                            setmail={setamail}
                            setperson={setaperson}
                            setcomment={setacomment}
                            setadress_street={setaadress_street}
                            setadress_nr={setaadress_nr}
                            setadress_plz={setaadress_plz}
                            setadress_city={setaadress_city}
                            adminList={data}
                            choosenAdmin={choosenAdmin}
                            setchoosenAdmin={setchoosenAdmin}
                            inputAdmin={inputAdmin}
                            setinputAdmin={() => setinputAdmin(!inputAdmin)}
                            deleteAdmin={deleteAdministrator}
                            deleteAdminScreen={deleteAdminScreen}
                            setdeleteAdminScreen={() => setdeleteAdminScreen(!deleteAdminScreen)}
                        />
                    )}
                </BaseModal>
            )}
        </>
    )
}
