import { useNavigate } from "react-router-dom"
import { OfferTableRowType } from "../../types"
import dayjs from "dayjs"
import { ReactComponent as WARNING } from "../../../../assets/WarningICON.svg"

export default function OfferTableRow({ id, title, customer, status, date, amount, archived, object }: OfferTableRowType) {
    const navigate = useNavigate()
    return (
        <tr
            onClick={() => navigate("/buchhaltung/angebote/" + id)}
            className={`border-b border-gray-200 cursor-pointer ${archived && "opacity-40 bg-gray-100"}`}>
            <td>
                <div
                    className={`h-3 w-3 rounded-full ml-1 ${
                        status === "settled"
                            ? "bg-green-500"
                            : status === "rejected"
                            ? "bg-red-500"
                            : status === "canceled"
                            ? "bg-gray-400"
                            : "bg-yellow-400"
                    }`}></div>
            </td>
            <td className="hidden sm:table-cell">
                <p className="line-clamp-1 text-base text-center">{dayjs(date).format("DD.MM.YYYY")}</p>
            </td>
            <td>
                <p title={title} className="line-clamp-1 text-base text-center">
                    {title}
                </p>
            </td>
            <td>
                <p className="line-clamp-1 text-base text-center">{amount.replace(".", ",")} €</p>
            </td>
            <td className="hidden md:table-cell">
                <p title={customer?.name} className="line-clamp-1 text-base flex justify-center">
                    {customer?.softDeleteAfter && (
                        <span className="pl-2">
                            <WARNING className="w-6 h-6 text-red-700" />
                        </span>
                    )}
                    {customer?.name}
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p
                    title={object?.adress?.street + " " + object?.adress?.nr + ", " + object?.adress?.plz + " " + object?.adress?.city}
                    className="line-clamp-1 text-base flex justify-center">
                    {object.softDeleteAfter && (
                        <span className="pr-2">
                            <WARNING className="w-6 h-6 text-red-700" />
                        </span>
                    )}
                    {object?.adress?.street} {object?.adress?.nr}, {object?.adress?.plz} {object?.adress?.city}
                </p>
            </td>
            <td className="hidden lg:table-cell">
                <p className="line-clamp-1 text-sm text-center">
                    {status === "settled" ? (
                        <div className="badge badge-success">Angenommen</div>
                    ) : status === "rejected" ? (
                        <div className="badge badge-outline">Abgelehnt</div>
                    ) : status === "unanswered" ? (
                        <div className="badge badge-warning">Ausstehend</div>
                    ) : status === "canceled" ? (
                        <div className="badge badge-error">Storniert</div>
                    ) : (
                        "Archiviert"
                    )}
                </p>
            </td>
        </tr>
    )
}
