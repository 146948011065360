import { useContext } from "react"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { useNavigate } from "react-router-dom"
import { ReactComponent as PLUS } from "../../../../assets/PlusICON.svg"

type ObjectDetailPartLeftListType = {
    objectListItems:
        | {
              nr: string | number | undefined
              billType?: string
              _id: string
          }[]
        | {
              first_name: string
              last_name: string
              _id: string
          }[]
    headline: string
    singular?: string
    link: string
    newtext: string
    linknew: string
}

export default function ObjectDetailPartLeftList(props: ObjectDetailPartLeftListType) {
    const modalCon = useContext(ModalContext)
    const navigate = useNavigate()
    return (
        <div className="h-fit w-full flex flex-col mt-2">
            <p className="font-bold">{props.headline}</p>
            <div className="h-48 overflow-y-scroll border-b-2">
                {props.objectListItems?.map(
                    (
                        item:
                            | {
                                  nr: string | number | undefined
                                  _id: string
                              }
                            | {
                                  first_name: string
                                  last_name: string
                                  _id: string
                              },
                        index: number
                    ) => (
                        <div
                            onClick={() => navigate(props.link + item._id)}
                            className="w-full hover:bg-gray-100 flex p-4 cursor-pointer border-b border-gray-100"
                            key={index}>
                            {"nr" in item && "billType" in item && item.billType + " " + item?.nr}
                            {"nr" in item && !("billType" in item) && props.singular + " " + item?.nr}
                            {"first_name" in item && "last_name" in item && item.first_name + " " + item?.last_name}
                        </div>
                    )
                )}
                <div
                    onClick={() => {
                        
                        navigate(props.linknew)
                        modalCon?.trigger(1)
                    }}
                    className="w-full hover:bg-gray-100 flex p-4 cursor-pointer border-b border-gray-100">
                    <PLUS className="w-6 h-6 mr-2 text-primary" />
                    {props.newtext}
                </div>
            </div>
        </div>
    )
}
