import { useQuery } from "@tanstack/react-query"
import { useLocation, useNavigate } from "react-router-dom"
import { downloadZipFromLinkPage } from "../../pages/api/apiCalls"
import Loading from "./Loading"

export default function DownloadPage() {
    const location = useLocation()

    // Parse query parameters
    const searchParams = new URLSearchParams(location.search)

    // Get the `id` parameter
    const id = searchParams.get("id")
    const type = searchParams.get("type")

    const navigate = useNavigate()
    const { } = useQuery({
        queryKey: ["DownloadZip" + id + type],
        queryFn: () => downloadZipFromLinkPage(`/export/download-zip?id=${id}&type=${type}`).then(() => navigate("/")),
        enabled: !!id && !!type,
    })

    return <Loading />
}
