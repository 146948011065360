import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { InfoElementType } from "../../Objekte/types/types"
import { CustomerCardType } from "../types/Types"
import { ReactComponent as RESTORE } from "../../../assets/RefreshICON.svg"
import { ReactComponent as MAPPIN } from "../../../assets/MapPinICON.svg"

export default function CustomerCard(props: CustomerCardType) {
    return (
        <div
            className={`w-full max-h-64 h-fit rounded-default shadow-lg flex flex-col px-4 pt-4 pb-6 bg-white relative ${
                props.archived && "text-gray-400 opacity-60"
            }`}>
            {props.deleted && <p className="absolute top-28 right-20 rotate-12 font-bold text-black/30 opacity-50 scale-150">Gelöscht</p>}
            <h3 title={props.name + " | " + props.owner} className="border-b mb-4 pb-1 text-lg 2xl:text-xl font-semibold px-2 truncate">
                {props.owner && props.owner !== props.name ? `${props.name}  |  ${props.owner}` : `${props.name}`}
            </h3>
            <InfoElement
                info={props.adress?.street + " " + props.adress?.nr + ", " + props.adress?.plz + ", " + props.adress?.city}
                icon={<MAPPIN className="w-6 h-6" />}
            />
            <InfoElement
                info={props.objects + " Objekte"}
                icon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"
                        />
                    </svg>
                }
            />
            <InfoElement
                info={props.offers + " offene Angebote"}
                icon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                    </svg>
                }
            />
            <InfoElement
                info={props.bills + " gestellte Rechnungen"}
                icon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                }
            />

            <RenderWhenAuthorized requiresAll={[Roles.kunden_read_write, Roles.kunden_delete]}>
                <div className="flex flex-row justify-end gap-2 grow items-center z-10">
                    <p title="Archivieren" onClick={props.archiveFunc}>
                        {props.archived ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 cursor-pointer">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 cursor-pointer">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                                />
                            </svg>
                        )}
                    </p>
                    {!props.deleted ? (
                        <p title="Löschen" onClick={props.deleteFunc}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 cursor-pointer">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                            </svg>
                        </p>
                    ) : (
                        <p title="Wiederherstellen" onClick={props.restoreFunc}>
                            <RESTORE className="w-6 h-6 cursor-pointer" />
                        </p>
                    )}
                </div>
            </RenderWhenAuthorized>
            <div onClick={props.openFunc} className="h-full w-full absolute top-0 left-0 cursor-pointer z-0" />
        </div>
    )
}

function InfoElement({ info, icon }: InfoElementType) {
    return (
        <div className="flex flex-row pt-0.5 2xl:pt-1 pl-2">
            {icon}
            <p title={info} className="pl-2 line-clamp-1 text-sm 2xl:text-base">
                {info}
            </p>
        </div>
    )
}
