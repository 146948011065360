import BaseInputField from "../../../../components/elements/BaseInputField"
import BaseDivider from "../../../../components/elements/BaseDivider"
import BaseInputArea from "../../../../components/elements/BaseInputArea"
import BaseInputSelection from "../../../../components/elements/BaseInputSelection"
import { ReactComponent as DeleteIcon } from "../../../../assets/DeleteICON.svg"
import { ReactComponent as CloseIcon } from "../../../../assets/CloseICON.svg"
import { ReactComponent as ApproveIcon } from "../../../../assets/ApprovaICON.svg"
import { validateEmail } from "../../../../services/functions"

type AddCustomerModalScreenType = {
    name: string
    tel: string
    mail: string
    person: string
    comment: string
    adress_street: string
    adress_nr: string
    adress_plz: string
    adress_city: string
    setname: (e: string) => void
    settel: (e: string) => void
    setmail: (e: string) => void
    setperson: (e: string) => void
    setcomment: (e: string) => void
    setadress_street: (e: string) => void
    setadress_nr: (e: string) => void
    setadress_plz: (e: string) => void
    setadress_city: (e: string) => void
    adminList?: any[] // TODO
    choosenAdmin?: any
    setchoosenAdmin?: (e: any) => void
    inputAdmin?: boolean
    setinputAdmin?: () => void
    deleteAdmin?: () => void
    deleteAdminScreen?: boolean
    setdeleteAdminScreen?: () => void
}

export default function AddCustomerModalScreen1({
    name,
    tel,
    mail,
    person,
    comment,
    adress_street,
    adress_nr,
    adress_plz,
    adress_city,
    setname,
    settel,
    setmail,
    setperson,
    setcomment,
    setadress_street,
    setadress_nr,
    setadress_plz,
    setadress_city,
    adminList,
    choosenAdmin,
    setchoosenAdmin,
    inputAdmin,
    setinputAdmin,
    deleteAdmin,
    deleteAdminScreen,
    setdeleteAdminScreen,
}: AddCustomerModalScreenType) {
    const handleButton = () => {
        if (setchoosenAdmin && setinputAdmin) {
            setchoosenAdmin("")
            setinputAdmin()
        }
    }

    return (
        <>
            {adminList && (
                <div className="flex flex-col items-center justify-center pt-4 gap-4">
                    {!inputAdmin && setchoosenAdmin && (
                        <BaseInputSelection
                            label={"Bestehenden Verwalter auswählen"}
                            placeholder={"Bitte Auswählen..."}
                            data={adminList}
                            setId={setchoosenAdmin}
                        />
                    )}
                    <div className="flex w-full justify-between">
                        {!inputAdmin && (
                            <div className="flex items-center max-w-md">
                                <button
                                    className={`btn btn-square ${deleteAdminScreen ? "btn-error" : "btn-primary btn-outline"}`}
                                    onClick={setdeleteAdminScreen}
                                    disabled={!choosenAdmin}>
                                    {!deleteAdminScreen ? <DeleteIcon className="w-6 h-6" /> : <CloseIcon className="w-6 h-6" />}
                                </button>
                                {deleteAdminScreen && (
                                    <>
                                        <p className="text-error text-sm px-4 w-fit">
                                            Wollen Sie den Verwalter wirklich löschen? Er wird danach nicht mehr zur Auswahl stehen.
                                        </p>
                                        <button className="btn btn-square btn-success" onClick={deleteAdmin}>
                                            <ApproveIcon className="w-6 h-6" />
                                        </button>
                                    </>
                                )}
                            </div>
                        )}
                        {!deleteAdminScreen && (
                            <button className="btn btn-primary " onClick={handleButton}>
                                {!inputAdmin ? "Neuen Verwalter-Datensatz hinzufügen" : "Bestehenden Verwalter auswählen"}
                            </button>
                        )}
                    </div>
                </div>
            )}
            {(inputAdmin || !adminList) && !deleteAdminScreen && (
                <>
                    <div className="flex flex-row gap-3 pt-2">
                        <BaseInputField classProps="pt-2" label={"Name *"} type={"text"} value={name} onChange={setname} />
                        <BaseInputField classProps="pt-2" label={"Telefonnummer *"} type={"text"} value={tel} onChange={settel} width="w-80" />
                    </div>
                    <BaseInputField classProps="pt-2" label={"Ansprechpartner"} type={"text"} value={person} onChange={setperson} />
                    <BaseInputField
                        status={!validateEmail(mail) && mail !== "" ? "error" : undefined}
                        classProps="pt-2"
                        label={"E-Mail-Adresse *"}
                        type={"text"}
                        value={mail}
                        onChange={setmail}
                        required
                    />
                    <BaseInputArea classProps="pt-2" label={"Bemerkung"} value={comment} onChange={setcomment} required={false} />
                    <BaseDivider />
                    <div className="flex flex-row gap-4">
                        <BaseInputField label={"Straße *"} type={"text"} value={adress_street} onChange={setadress_street} />
                        <BaseInputField width="w-48" label={"Hausnummer *"} type={"text"} value={adress_nr} onChange={setadress_nr} />
                    </div>
                    <div className="flex flex-row gap-4 pb-4">
                        <BaseInputField width="pt-2 w-64" label={"PLZ *"} type={"text"} value={adress_plz} onChange={setadress_plz} />
                        <BaseInputField
                            prevent
                            classProps="pt-2 w-3/4"
                            label={"Stadt *"}
                            type={"text"}
                            value={adress_city}
                            onChange={setadress_city}
                        />
                    </div>
                </>
            )}
        </>
    )
}
