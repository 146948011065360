import { useNavigate } from "react-router-dom"
import { PaymentTableRowType } from "../../types"
import dayjs from "dayjs"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../../auth/RoleMapping"
import { ReactComponent as EscalationIcon } from "./../../../../assets/EscalationRequiredICON.svg"
import { ReactComponent as INFO } from "./../../../../assets/InfoICON.svg"

export default function PaymentTableRow({
    id,
    title,
    customer,
    status,
    date,
    delivery,
    amount,
    type,
    archived,
    escalationRequired,
    openSolveEscalationModal,
    modalOpen,
}: PaymentTableRowType) {
    const navigate = useNavigate()
    const target = dayjs(delivery)
    const today = dayjs()

    return (
        <tr className={`border-b border-gray-200 cursor-pointer relative  ${archived && "opacity-40 bg-gray-100"}`}>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + id)}>
                <div
                    className={`h-3 w-3 rounded-full ml-1 ${
                        status === "settled" ? "bg-green-500" : target < today && status === "unpaid" ? "bg-red-500" : "bg-yellow-400"
                    }`}></div>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + id)} className="hidden lg:table-cell">
                <p className="line-clamp-1 text-base text-center">{dayjs(delivery).format("DD.MM.YYYY")}</p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + id)}>
                <p title={title} className="line-clamp-1 text-base text-center">
                    Rechnung {title}
                </p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + id)}>
                <p className="line-clamp-1 text-base text-center">{amount?.toFixed(2)?.toString()?.replace(".", ",")} €</p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + id)} className="hidden md:table-cell">
                <p title={customer} className="line-clamp-1 text-base text-center">
                    {customer}
                </p>
            </td>
            <td onClick={() => navigate("/buchhaltung/rechnung/" + id)} className="hidden xl:table-cell">
                <p className={`line-clamp-1 text-center `}>
                    {escalationRequired ? (
                        <div className="badge badge-error">Mahnungen Überfällig</div>
                    ) : status === "settled" ? (
                        <div className="badge badge-success">Beglichen</div>
                    ) : status === "canceled" ? (
                        <div className="badge badge-outline">Storniert</div>
                    ) : status === "unpaid" && target < today ? (
                        <div className="badge badge-error">{`${today.diff(target, "day") + 1} Tage überfällig`}</div>
                    ) : (
                        <div className="badge badge-warning">Ausstehend</div>
                    )}
                </p>
            </td>
            <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_zahlungen_read_write]}>
                {!escalationRequired ? (
                    <td onClick={modalOpen}>
                        <INFO className="w-6 h-6 z-10 " />
                    </td>
                ) : (
                    <td onClick={openSolveEscalationModal}>
                        <EscalationIcon className="w-6 h-6 z-10 " />
                    </td>
                )}
            </RenderWhenAuthorized>
        </tr>
    )
}
