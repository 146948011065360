import { useState } from "react"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { ignoreTransaction, setReminder, updateTransactionAndBillData, updateTransactionAndBillPullData } from "./apiCalls"
import { messageError, messagePending, messageSuccess } from "../../../services/messages"

// TODO
export const useUpdateTransactionMutation = (
    queryClient: QueryClient,
    refreshDataPayment: string,
    refreshDataTransactionsAll?: string
) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<
        unknown,
        Error,
        {
            pid: string
            tid: string
        }
    >({
        mutationFn: updateTransactionAndBillData,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Transaktionen werden gerade aktualisiert.")
        },
        onSuccess: async () => {
            messageSuccess(notificationKey, "Fertig!", "Transaktionen wurden erfolgreich aktualisiert.")
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: [refreshDataPayment],
                }),
                refreshDataTransactionsAll &&
                    queryClient.invalidateQueries({
                        queryKey: [refreshDataTransactionsAll],
                    }),
            ])
        },

        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Transaktionen konnten nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

// TODO
export const useUpdateTransactionPullMutation = (queryClient: QueryClient, refreshDataPayment: string, refreshDataTransactionsAll: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<
        unknown,
        Error,
        {
            pid: string
            tid: string
        }
    >({
        mutationFn: updateTransactionAndBillPullData,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Status der Zahlung wird gerade aktualisiert.")
        },
        onSuccess: async () => {
            messageSuccess(notificationKey, "Fertig!", "Der Zahlungsstatus wurde erfolgreich aktualisiert.")
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: [refreshDataPayment],
                }),
                queryClient.invalidateQueries({
                    queryKey: [refreshDataTransactionsAll],
                }),
            ])
        },

        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Zahlungsstatus konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

// NOTE done
export const useIgnoreTransactionMutation = (queryClient: QueryClient, refreshDataTransaction: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, string>({
        mutationFn: ignoreTransaction,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Die Transaktionen werden gerade aktualisiert.")
        },
        onSuccess: () => {
            messageSuccess(notificationKey, "Fertig!", "Transaktionen wurden erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: [refreshDataTransaction],
            })
        },

        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Transaktionen konnten nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}

// NOTE done
export const useSetReminderMutation = (queryClient: QueryClient, modalCon: any, modalId: number, refreshDataPayment: string) => {
    const [notificationKey, setnotificationKey] = useState<string>("")
    const mutation = useMutation<unknown, Error, String>({
        mutationFn: setReminder,
        onMutate: () => {
            const newKey = Math.random().toString(36).substring(2, 12)
            setnotificationKey(newKey)
            messagePending(newKey, "In Bearbeitung...", "Der Status der Mahnung wird gerade aktualisiert.")
        },
        onSuccess: (data, variables) => {
            messageSuccess(notificationKey, "Fertig!", "Der Status der Mahnung wurde erfolgreich aktualisiert.")

            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: [refreshDataPayment],
            })
        },
        onError: (err) => {
            messageError(notificationKey, "Fehler!", "Der Status der Mahnung konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })

    return mutation
}
