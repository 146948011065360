import { useNavigate } from "react-router-dom"
import { ObjectType } from "../../../Objekte/types/types"

type CustomerDetailPartRightType = {
    data: ObjectType[] // TODO any
}

export default function CustomerDetailPartRight(props: CustomerDetailPartRightType) {
    const navigate = useNavigate()
    return (
        <div className="hidden lg:flex flex-col w-full bg-white p-4 rounded-default shadow-lg">
            <h3 className="text-sm">Objekte des Kunden</h3>
            <div className="h-full overflow-y-scroll w-full max-h-[42rem]">
                {props.data?.length === 0 ? (
                    <p className="pt-2 text-sm italic">Dem Kunden sind noch keine Objekte zugeordnet</p>
                ) : (
                    props.data?.map((item: ObjectType, index: number) => (
                        <div key={index} className="w-full border-b py-4 px-4 flex flex-col hover:bg-black/10 cursor-pointer" onClick={() => navigate("/objekte/" + item._id)}>
                            <p
                                className="line-clamp-1"
                                title={item?.adress?.street + " " + item?.adress?.nr + ", " + item?.adress?.city + " " + item?.adress?.plz}>
                                {item?.adress?.street + " " + item?.adress?.nr + ", " + item?.adress?.city + " " + item?.adress?.plz}
                            </p>
                            <div className="flex flex-wrap">
                                <p>
                                    Offene Angebote: <span>{item.statistics?.offers.open}</span>
                                </p>
                                <p className="px-4">|</p>
                                <p>
                                    Offene Rechnungen: <span>{item.statistics?.bills.open}</span>
                                </p>
                                <p className="px-4">|</p>
                                <p>
                                    Gestellte Angebote: <span>{item.statistics?.offers.total}</span>
                                </p>
                                <p className="px-4">|</p>
                                <p>
                                    Gestelle Rechnungen: <span>{item.statistics?.bills.total}</span>
                                </p>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}
