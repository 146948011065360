import { motion } from "framer-motion"
import { NewOfferScreen1 } from "../../types"
import BaseInputSelection from "../../../../components/elements/BaseInputSelection"
import BaseInputArea from "../../../../components/elements/BaseInputArea"
export default function OfferModalScreen1(props: NewOfferScreen1) {
    return (
        <motion.div
            initial={{
                x: 0,
            }}
            animate={{
                x: props.visible ? 0 : "-100%",
            }}
            transition={{
                type: "ease",
            }}
            className="absolute top-0 left-0 w-full h-full overflow-hidden pl-2">
            <BaseInputSelection
                value={props.searchTerm}
                setId={props.setcustomer}
                label={"Kunde *"}
                placeholder={"Kunde auswählen"}
                data={props.customerArray ?? []}
                classProps="pt-2"
                onSearch={(e: string) => props.setsearchTerm(e)}
                customLoading={props.isLoadingCustomer}
                customError={props.errorCustomer}
            />
            <BaseInputSelection
                classProps="pt-2"
                setId={props.setobject}
                label={"Objekt *"}
                value={props.objectId}
                placeholder={"Objekt auswählen"}
                data={props.objectArray ?? []}
                disabled={props.objectArray?.length === 0 || !props.objectArray}
            />
            {props.customerId !== "" && props.objectArray?.length === 0 && (
                <p className="text-xs text-gray-300">Diesem Kunde sind keine Objekte zugewiesen.</p>
            )}
            <BaseInputArea
                classProps="mt-2"
                label={"Zusätzlicher Text auf dem Angebot"}
                value={props.additionalText}
                onChange={props.setadditionalText}
            />
        </motion.div>
    )
}
