import { BaseFilterButtonType } from "../Types"
import { ReactComponent as FILTER } from "../../assets/FilterICON.svg"

export default function BaseFilterButton({ func }: BaseFilterButtonType) {
    return (
        <button onClick={func} className="btn bg-white shadow-lg border-none hover:bg-white/40">
            <FILTER className="w-6 h-6" />
            <span className="hidden 2xl:block">Filter</span>
        </button>
    )
}
