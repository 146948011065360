import { useState } from "react"
import { AssignBillElementType } from "../../types"
import { motion } from "framer-motion"

export function AssignPayReqElement(props: AssignBillElementType) {
    const [check, setcheck] = useState(false)
    return (
        <div className="w-full relative">
            <motion.div
                initial={{
                    x: 0,
                    backgroundColor: "#fff",
                }}
                animate={{
                    x: check ? "-18rem" : 0,
                    backgroundColor: check ? "#f2f5f8" : "#fff",
                }}
                transition={{
                    duration: 0.1,
                    type: "ease",
                }}
                className="z-10 bg-white flex flex-row w-full px-4 py-2 gap-2 relative border-b items-center cursor-pointer"
                onClick={() => setcheck(!check)}>
                <div className="w-20 mr-2 text-xs">Fällig am {props.due_date}</div>
                <div className="w-20 mx-2 text-xs">Ausgestellt {props.invoice_date}</div>
                <div className={`w-40 text-right font-bold ${props.status ? "text-green-500" : "text-red-600"}`}>{props?.amount?.toString()} €</div>
                <div className="w-64 text-sm text-right pr-2">Rechnung {props.nr}</div>
                <div className="w-full pl-2 pr-8 text-sm">
                    <p className="line-clamp-1 text-gray-400">
                        {props.customer}, {props.administrator}
                    </p>
                </div>
            </motion.div>
            <div
                onClick={() => {
                    props.func()
                    setcheck(!check)
                }}
                title="Rechnung als Bezahlt markieren"
                className="cursor-pointer absolute right-0 z-0 h-full bg-green-300 w-72 top-0 flex items-center justify-center">
                <p className="uppercase font-semibold tracking-wider pr-1">Diese Zahlung zuordnen</p>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                </svg>
            </div>
        </div>
    )
}
