import { useQuery, useQueryClient } from "@tanstack/react-query"
import BaseModal from "../../../../components/layout/BaseModal"
import ErrorPage from "../../../404/ErrorPage"
import dayjs from "dayjs"
import { transactionElementType } from "../../types"
import { useMemo, useState } from "react"
import { useUpdateTransactionMutation } from "../../api/services"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../../auth/RoleMapping"
import Loading from "../../../../components/common/Loading"
import { AssignPayReqElement } from "./AssignPayReqElement"
import { useDelayWhenSearchWithPagination } from "../../../../services/functions"
import { getPaginatedData } from "../../../api/apiCalls"
import { defaultTransactionStatus } from "../../../../data/defaultValues"

type AssignTransactionModalType = {
    modalId: number
    transactionReloadLink: string
    transaction?: transactionElementType
    closeModal: () => void
}
export default function AssignTransactionModal(props: AssignTransactionModalType) {
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }
    const [searchTerm, setsearchTerm] = useState("")
    const [searchStatus, setsearchStatus] = useState("unpaid")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const { data, error, isLoading } = useQuery({
        queryKey: ["paymentsData" + searchParams + searchStatus + page],
        queryFn: () =>
            getPaginatedData({
                pageType: "paymentrequirements",
                page: page,
                limit: 15,
                search: searchParams,
                status: searchStatus,
            }),
    })

    const displayedPayReq = useMemo(() => data?.docs, [data?.docs])

    const [showAll, setshowAll] = useState<boolean>(false)
    const queryClient = useQueryClient()
    const { mutate: assignTransaction } = useUpdateTransactionMutation(
        queryClient,
        "paymentsData" + searchParams + searchStatus + page,
        props.transactionReloadLink
    )
    const handleClick = (pid: string) => {
        setshowAll(false)
        assignTransaction({
            pid: pid,
            tid: props?.transaction?._id ?? "",
        })
        props.closeModal()
    }

    return (
        <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_zahlungen_read_write]}>
            <BaseModal
                title={"Rechnung zuordnen"}
                modalId={props.modalId}
                bottomRow={
                    <button onClick={() => setshowAll(!showAll)} className="btn btn-primary">
                        {showAll ? "Nur Unbezahlte" : "Alle Anzeigen"}
                    </button>
                }>
                <div className="border-b-2 flex flex-row px-6 pb-2 pt-3 gap-4">
                    <div className="flex flex-col w-40 items-end">
                        <p className="underline text-xs text-gray-400">Betrag:</p>
                        <p
                            className={`font-bold text-xl ${
                                props.transaction?.processingStatus === defaultTransactionStatus.UNASSIGNED
                                    ? "text-red-600"
                                    : props.transaction?.processingStatus === defaultTransactionStatus.ASSIGNED
                                    ? "text-green-500"
                                    : "text-black"
                            }`}>
                            {props.transaction?.paymentInformation.transactionAmount.amount.toString().replace(".", ",")} €
                        </p>
                        <p className="underline text-xs text-gray-400 pt-1">Datum:</p>
                        <p className="font-semibold text-gray-500">{dayjs(props.transaction?.bookingTimestamp).format("DD.MM.YYYY")}</p>
                    </div>
                    <div className="flex flex-col items-start w-fit overflow-y-scroll">
                        <p className="underline text-xs text-gray-400">Sender: </p>
                        {props.transaction?.paymentInformation.counterpartNames.map((item, index) => (
                            <p className="text-xs text-gray-400 w-fit" title={item} key={index}>
                                {item}
                            </p>
                        ))}
                    </div>
                    <div className="flex flex-col items-start w-full">
                        <p className="underline text-xs text-gray-400">Verwendungszweck: </p>
                        <p className="line-clamp-2 text-xs text-gray-400" title={props.transaction?.paymentInformation.remittanceText}>
                            {props.transaction?.paymentInformation.remittanceText}
                        </p>
                    </div>
                </div>
                <div className="flex flex-col h-[80%] overflow-y-scroll overflow-x-hidden">
                    {error && <ErrorPage />}
                    {isLoading && <Loading />}
                    {data && !isLoading && !error && (
                        <>
                            {displayedPayReq.map(
                                (
                                    item: any,
                                    index: number // TODO type ändern
                                ) => (
                                    <AssignPayReqElement
                                        func={() => handleClick(item._id)}
                                        key={index}
                                        due_date={dayjs(item?.due_date).format("DD.MM.YYYY")}
                                        invoice_date={dayjs(item.invoice_date).format("DD.MM.YYYY")}
                                        nr={item?.bill_id?.nr}
                                        amount={item.expected_payment}
                                        customer={item?.customer_id?.customer?.name}
                                        administrator={item?.customer_id?.administrator?.name}
                                        status={item.status === "settled"}
                                    />
                                )
                            )}
                        </>
                    )}
                </div>
            </BaseModal>
        </RenderWhenAuthorized>
    )
}
