import dayjs from "dayjs"
import { EmployeeCardModal } from "../../Objekte/types/types"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { ReactComponent as ARCHIVE } from "../../../assets/ArchiveICON.svg"
import { ReactComponent as UNARCHIVE } from "../../../assets/UnArchiveICON.svg"
import { ReactComponent as DELETE } from "../../../assets/DeleteICON.svg"

export default function EmployeeCard({ item, selected, setEmployee, deleteFunc, archiveFunc }: EmployeeCardModal) {
    return (
        <div
            onClick={setEmployee}
            className={`bg-white cursor-pointer flex flex-col w-full h-fit rounded-default shadow-md p-4 relative border ${
                item.archived && "opacity-60 text-gray-400"
            } ${selected ? "border-black" : ""}`}>
            <h3 className="font-semibold text-lg 2xl:text-xl">
                {item.first_name} {item.last_name}
            </h3>
            <div className="border-b w-full my-2" />
            <p className="text-sm">Geburtstag: {dayjs(item.geb).format("DD.MM.YYYY")}</p>
            <p className="text-sm">Position: {item.position}</p>
            {/* NOTE currently disabled bc no function in prod */}
            {/* <p className="text-sm">Objekte in Betreuung: {item.objects?.length ?? 0} </p> */}
            <p className="text-sm">Offene Aufgaben: {item.tasks?.length ?? 0}</p>

            <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_read_write, Roles.mitarbeiter_delete]}>
                <div className="flex flex-row justify-end gap-2 grow items-center">
                    <p title="Archivieren" onClick={archiveFunc}>
                        {item.archived ? <UNARCHIVE className="w-6 h-6 cursor-pointer" /> : <ARCHIVE className="w-6 h-6 cursor-pointer" />}
                    </p>
                    <p title="Löschen" onClick={deleteFunc}>
                        <DELETE className="w-6 h-6 cursor-pointer" />
                    </p>
                </div>
            </RenderWhenAuthorized>
        </div>
    )
}
