import dayjs from "dayjs"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { SidePanelContext } from "../../../../../components/contexts/SidePanelContext"
import { BillGet } from "../../../../../data/Types"

type BillDetailHeadType = {
    data: BillGet
    connectedOffer: any
    setResendUri: (e: string) => void
    setMailPreviewContent: (e: string) => void
}

export default function BillDetailHead(props: BillDetailHeadType) {
    console.log(props.data.billDay)
    const sidePanelCon = useContext(SidePanelContext)
    return (
        <div className="flex gap-4 h-fit">
            {/* Basic Information about Bill */}
            <div className="w-full h-fit rounded-default shadow-lg mt-6 bg-white p-4">
                <InfoRow link={"/kunden/" + props.data?.customer_id?._id} info={"Kunde"} content={props.data?.customer_id?.customer?.name} />
                <InfoRow
                    link={"/objekte/" + props.data?.object_id?._id}
                    info={"Objekt"}
                    content={
                        props.data?.object_id?.adress?.street +
                        " " +
                        props.data?.object_id?.adress?.nr +
                        ", " +
                        props.data?.object_id?.adress?.city +
                        " " +
                        props.data?.object_id?.adress?.plz
                    }
                />
                <InfoRow info={window.innerWidth > 640 ? "ausgestellt am" : "ausgestellt"} content={dayjs(props.data.date).format("DD.MM.YYYY")} />
                <InfoRow info={window.innerWidth > 640 ? "Rechnungstyp" : "Typ"} content={props.data?.billType} />
                <InfoRow info={"Archiviert"} content={props.data?.archived ? "Ja" : "Nein"} />
                <InfoRow
                    link={props.data?.offer_id && "/buchhaltung/angebote/" + props.data?.offer_id}
                    info={"Angebot"}
                    content={props.data?.offer_id ? "Angebot " + props.connectedOffer?.nr : "kein verknüpftes Angebot"}
                />
                <InfoRow
                    info={"Mahnungssystem"}
                    content={
                        props.data?.manualReminderEnabled
                            ? `Mahnung nach ${props.data.reminderStartOffsetInDays} Tagen, im Abstand von ${props.data.reminderIntervalInDays} Tagen, maximal ${props.data.maxReminders} Mahnungen`
                            : "Systemeinstellung"
                    }
                />
                {props.data.billType !== "Rechnung" && <InfoRow info={"Bezahlen bis zum"} content={props.data.billDay + ". eines Monats"} />}
                <InfoRow info={"Zahlungsfrist"} content={props.data.billDue + " Tage"} />
            </div>
            {/* Information about Mail */}
            {props.data.mailReports && props.data.mailReports.length > 0 && (
                <div className="w-full grow rounded-default shadow-lg mt-6 bg-white p-4">
                    <InfoRow
                        info={"Mail versandt am"}
                        content={props.data.mailReports.map(
                            (item: any) =>
                                dayjs(item.date).format("DD.MM.YYYY") +
                                (props.data.mailReports.indexOf(item) + 1 !== props.data.mailReports.length ? ", " : "")
                        )}
                    />
                    <InfoRow
                        info={"Empfänger"}
                        content={props.data?.mailReports[0]?.mailReceivers?.map(
                            (item: string) =>
                                item +
                                (props.data.mailReports[0].mailReceivers?.indexOf(item) + 1 !== props.data.mailReports[0].mailReceivers.length
                                    ? ", "
                                    : "")
                        )}
                    />
                    <InfoRow
                        info={"Erfolgreich versandt"}
                        content={props.data?.mailReports.find((item: { mailDeliverySuccess: boolean }) => item.mailDeliverySuccess) ? "Ja" : "Nein"}
                    />
                    <InfoRow info={"Absendeversuche"} content={props.data?.mailReports?.length?.toString()} />
                    <div className="flex items-end justify-start grow w-full pt-4 gap-2">
                        <button
                            disabled={!props.data.mailReports[0].mailContent}
                            className="btn btn-outline btn-primary btn-sm"
                            onClick={() => {
                                props.setResendUri(props.data.mailReports[0].resendUri)
                                props.setMailPreviewContent(props.data.mailReports[0].mailContent)
                                sidePanelCon?.trigger(4)
                            }}>
                            Vorschau
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

type InfoRowType = {
    info: string
    content: string | any
    link?: string
}

function InfoRow({ info, content, link }: InfoRowType) {
    const navigate = useNavigate()

    return (
        <div onClick={link ? () => navigate(link) : undefined} className="flex flex-row gap-8 text-base items-center justify-start">
            <p className="font-medium w-24 sm:w-40">{info}:</p>
            <p className={link && "cursor-pointer hover:underline truncate"}>{content}</p>
        </div>
    )
}
