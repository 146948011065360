import { API } from "../../api/constants"
import { authenticatedAxiosInstance } from "../../auth/AuthenticatedAxios"
import { ArchiveDataParams, DeleteDataParams, PostNewDataParams } from "./Types/Types"

// NOTE - general api requests, which are used from different pages
type getPaginatedDataType = {
    pageType: string
    page: number
    limit: number
    search?: string
    status?: string
    startDate?: string
    endDate?: string
    archived?: boolean
    deleted?: boolean
    canceled?: boolean
    invertSort?: boolean
    onlyUnassigned?: boolean
    billCategorie?: string
    billType?: string
    position?: string
}
// get paginated data - bill / offer / paymentrequirements / object / customer / employee
export const getPaginatedData = ({
    pageType,
    page,
    limit,
    search,
    status,
    startDate,
    endDate,
    archived,
    deleted,
    canceled,
    invertSort,
    billCategorie,
    billType,
    position,
    onlyUnassigned,
}: getPaginatedDataType) =>
    authenticatedAxiosInstance
        .get(API + `/${pageType}/paginate`, {
            params: {
                page: page || undefined,
                limit: limit || undefined,
                search: search || undefined,
                status: status || undefined,
                startDate: startDate || undefined,
                endDate: endDate || undefined,
                archived: archived === true ? true : archived === false ? false : undefined,
                deleted: deleted === true ? true : deleted === false ? false : undefined,
                canceled: canceled === true ? true : canceled === false ? false : undefined,
                invertSort: invertSort || undefined,
                billCategory: billCategorie || undefined,
                billType: billType || undefined,
                position: position || undefined,
                onlyUnassigned: onlyUnassigned || undefined,
            },
        })
        .then((res) => res.data)

// add data - object / customer / employee
export const postNewDataGeneral = (data: PostNewDataParams) =>
    authenticatedAxiosInstance.post(API + `/${data.pageType}/add`, data.body).then((res) => res.data)

// delete data -
export const deleteDataGeneral = (data: DeleteDataParams) =>
    authenticatedAxiosInstance.delete(API + `/${data.pageType}/delete/` + data.id).then((res) => res.data)

// update archive data - object / customer / employee
export const archiveDataGeneral = (data: ArchiveDataParams) =>
    authenticatedAxiosInstance.patch(API + `/${data.pageType}/archive/update/` + data.id, data.body).then((res) => res.data)

// get document from any type
export const getDocumentFromAnyType = (data: { id: string; s3Key: string; filetype: string }) =>
    authenticatedAxiosInstance
        .get(API + `/${data.filetype}/${data.id}/download?s3Key=${data.s3Key}`, { responseType: "blob" })
        .then((res) => res.data)

// pdf preview
export const getPdfFileToPreview = (data: { s3Key: string }) =>
    authenticatedAxiosInstance
        .get(API + `/documents/preview/pdf?s3Key=${data.s3Key}`, {
            responseType: "arraybuffer",
        })
        .then((res) => res.data)

// start zip extraction
export const startZipExtraction = (data: { id: string; pageType: string }) =>
    authenticatedAxiosInstance.post(API + `/${data.pageType}/zip-export/${data.id}`).then((res) => res.data)

// restore smth
export const restoreData = (data: { id: string; pageType: string }) =>
    authenticatedAxiosInstance.patch(API + `/${data.pageType}/restore/${data.id}`).then((res) => res.data)

// download zip from link-page
export const downloadZipFromLinkPage = async (zipUrl: string) => {
    try {
        const response = await authenticatedAxiosInstance.get(`${API + zipUrl}`, {
            responseType: "blob",
        })

        let fileName = response.headers["content-disposition"]?.split("filename=")?.[1] ?? "export.zip"

        const blob = new Blob([response.data], { type: "application/zip" })

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob)

        // Create an anchor element
        const a = document.createElement("a")

        a.href = url
        a.download = fileName

        // Append the anchor to the body
        document.body.appendChild(a)

        // Programmatically click the anchor to trigger download
        a.click()

        // Clean up: remove the anchor and revoke the object URL
        a.remove()
        window.URL.revokeObjectURL(url)

        return URL.createObjectURL(response.data)
    } catch (error) {
        console.error("Error fetching image:", error)
        throw error
    }
}
