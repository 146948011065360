import React, { useContext, useState } from "react"
import BaseModal from "../../../../components/layout/BaseModal"
import { useQueryClient } from "@tanstack/react-query"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { useAddBillMutation } from "../../api/services"
import { BillFromOfferType, BillPost } from "../../../../data/Types"
import dayjs from "dayjs"
import BaseInputField from "../../../../components/elements/BaseInputField"
import BaseSelection from "../../../../components/elements/BaseSelection"
import BaseInputArea from "../../../../components/elements/BaseInputArea"

export default function BillFromOfferModal(props: BillFromOfferType) {
    const [billType, setbillType] = useState("Rechnung")

    const [billCategorie, setbillCategorie] = useState("Hausmeister")
    const [billDue, setbillDue] = useState<string>("28")
    const [billDay, setbillDay] = useState<string>("15")
    const [deliveryDate, setdeliveryDate] = useState("")
    const [additionalText, setadditionalText] = useState<string | undefined>()

    const clearInput = () => {
        setbillCategorie("Hausmeister")
        setbillDay("15")
        setbillDue("28")
        setdeliveryDate("")
        setbillType("Rechnung")
        setadditionalText(undefined)
    }

    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)
    const { mutate: addBill } = useAddBillMutation(queryClient, props.offer)
    const handleNewBill = () => {
        const NewBill: BillPost = {
            date: Date(),
            customer_id: props.customer._id,
            object_id: props.object._id,
            offer_id: props.offer,
            positions: props.positions,
            billCategorie: billCategorie,
            billType: billType,
            billDue: Number(billDue),
            serviceDate: dayjs(deliveryDate).format("DD.MM.YYYY"),
            additionalText: additionalText
        }
        if (billType !== "Rechnung") {
            NewBill.billDay = Number(billDay)
        }
        const formData = new FormData()
        formData.append("bill", JSON.stringify(NewBill))
        props.documents?.forEach((doc: any) => {
            if (doc) {
                formData.append("additionalDocuments", doc)
            }
        })
        addBill(formData)
        
        clearInput()
        modalCon?.trigger(0)
    }

    return (
        <BaseModal
            modalId={1}
            title={"Rechnung stellen"}
            bottomRow={
                <div className="flex justify-end items-center">
                    <button className="btn btn-success" onClick={handleNewBill} disabled={deliveryDate === ""}>
                        Rechnung stellen
                    </button>
                </div>
            }>
            <div className="flex gap-4">
                <BaseSelection
                    label={"Rechnungstyp *"}
                    placeholder={""}
                    classProps="mt-4"
                    data={["Rechnung", "Jahresrechnung", "Dauerrechnung"]}
                    setId={setbillType}
                    defaultValue="Rechnung"
                />
                <BaseSelection
                    label={"Kategorie *"}
                    placeholder={""}
                    classProps="mt-4 !w-64"
                    data={["Hausmeister", "Schlüsseldienst", "Bau"]}
                    setId={setbillCategorie}
                    defaultValue="Hausmeister"
                />
            </div>
            <div className="flex flex-col w-full mt-4">
                <label className="text-sm pl-2 pb-1">Kunde *</label>
                <p className="p-4 border-2 border-black rounded-md">{props.customer.name}</p>
            </div>
            <div className="flex flex-col w-full mt-4">
                <label className="text-sm pl-2 pb-1">Objekt *</label>
                <p className="p-4 border-2 border-black rounded-md">{props.object.adress}</p>
            </div>
            <BaseInputField label={"Leistungsdatum"} type={"date"} value={deliveryDate} onChange={setdeliveryDate} classProps="mt-2" />
            <BaseSelection
                label={"Rechnungsfrist in Tagen *"}
                placeholder={""}
                classProps="mt-2"
                data={["7", "14", "28"]}
                setId={setbillDue}
                defaultValue="28"
            />
            {billType !== "Rechnung" && (
                <BaseSelection
                    label={"Zahltag des Monats *"}
                    placeholder={""}
                    classProps="mt-2"
                    data={["1", "15", "28"]}
                    setId={setbillDay}
                    defaultValue="28"
                />
            )}
            <BaseInputArea
                prevent
                classProps="mt-2"
                label={"Zusätzlicher Text auf der Rechnung"}
                value={additionalText}
                onChange={setadditionalText}
            />
        </BaseModal>
    )
}
