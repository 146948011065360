import React, { useContext } from "react"
import { RenderWhenAuthorized } from "../../../../../components/authentication/RenderWhenAuthorized"
import { Roles } from "../../../../../auth/RoleMapping"
import { ModalContext } from "../../../../../components/contexts/ModalContext"

type BillDetailButtonRowType = {
    canceled: boolean
    handleDownload: () => void
    handleChangePreviewElement: () => void
}

export default function BillDetailButtonRow(props: BillDetailButtonRowType) {
    const modalCon = useContext(ModalContext)

    return (
        <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
            <div className="flex flex-row justify-end p-4 rounded-default shadow-lg bg-white mt-4 gap-2">
                <button onClick={props.handleChangePreviewElement} className="hidden sm:block btn btn-primary btn-outline">
                    Vorschau
                </button>
                <button onClick={() => props.handleDownload()} className="hidden sm:block btn btn-primary btn-outline">
                    Download
                </button>
                <button onClick={() => modalCon?.trigger(0)} className="btn btn-primary btn-outline">
                    Archivieren
                </button>
                {!props.canceled && (
                    <button onClick={() => modalCon?.trigger(2)} className="hidden sm:block btn btn-primary btn-outline">
                        Stornieren
                    </button>
                )}
            </div>
        </RenderWhenAuthorized>
    )
}
