import { Input } from "antd"
import { preventKeyDown } from "../../services/functions"
import { ReactComponent as SEARCH } from "../../assets/SearchICON.svg"

type BaseSearchInputFieldType = {
    searchFunction: (e: any) => void
    value: string | number
    placeholder: string
    small?: boolean
    classProps?: string
}

export default function BaseSearchInputField(props: BaseSearchInputFieldType) {
    return (
        <Input
            prefix={<SEARCH className="w-4 h-4" />}
            placeholder={props.placeholder}
            onKeyDown={preventKeyDown}
            onChange={(e) => props.searchFunction(e.target.value)}
            value={props.value ?? null}
            className={`w-80 ${props.classProps}`}
        />
    )
}
