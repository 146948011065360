import React, { useContext, useMemo, useState } from "react"
import BasePage from "../../../components/layout/BasePage"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { useNavigate } from "react-router-dom"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import OfferTableRow from "../components/offer/OfferTableRow"
import { useQuery } from "@tanstack/react-query"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import AddOfferModal from "../components/offerModal/AddOfferModal"
import { OfferGet } from "../../../data/Types"
import { preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"
import BaseFilterModal from "../../../components/elements/BaseFilterModal"
import { ReactComponent as PLUSINCIRCLE } from "../../../assets/PlusInCircleIcon.svg"
import { ReactComponent as BILL } from "../../../assets/BillIcon.svg"
import { ReactComponent as PAYMENT } from "../../../assets/PaymentIcon.svg"
import { ReactComponent as ARROWUP } from "../../../assets/ArrowUpIcon.svg"
import { ReactComponent as ARROWDOWN } from "../../../assets/ArrowDownIcon.svg"

export default function OfferPage() {
    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const [filterStatus, setfilterStatus] = useState("")
    const [filterDateSince, setfilterDateSince] = useState("")
    const [filterDateTo, setfilterDateTo] = useState("")
    const [filterArchived, setfilterArchived] = useState<boolean | undefined>(false)
    const [invertSort, setinvertSort] = useState<boolean | undefined>()

    // Get Data Query
    const queryKey = "offerData" + page + searchParams + filterStatus + filterDateSince + filterDateTo + filterArchived + invertSort
    const { error, data, isLoading } = useQuery({
        queryKey: [queryKey],
        queryFn: () =>
            getPaginatedData({
                pageType: "offer",
                page: page,
                limit: 15,
                search: searchParams,
                status: filterStatus,
                startDate: filterDateSince,
                endDate: filterDateTo,
                archived: filterArchived,
                invertSort: invertSort,
            }),
    })

    const navigate = useNavigate()
    const modalCon = useContext(ModalContext)
    const [showFilter, setshowFilter] = useState(false)

    const handleRemoveFilter = () => {
        setfilterStatus("")
        setfilterDateTo("")
        setfilterDateSince("")
        setfilterArchived(false)
        setinvertSort(undefined)
    }

    const displayedOffers = useMemo(() => data?.docs, [data?.docs])

    return (
        <BasePage>
            <BaseHeadBanner
                title={"Angebote"}
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
                        <PLUSINCIRCLE className="w-8 h-8 cursor-pointer hidden md:block" onClick={() => modalCon?.trigger(1)} />
                    </RenderWhenAuthorized>
                }
            />
            {/* Button Row */}
            <section className="w-full flex flex-wrap justify-between gap-4 py-4 md:px-4 items-center relative  max-h-[18vh]">
                <input
                    onKeyDown={preventKeyDown}
                    onChange={(e) => setsearchTerm(e.target.value)}
                    type="text"
                    value={searchTerm ?? null}
                    placeholder="Nach Kunden suchen..."
                    className="w-full max-w-xs rounded-default bg-none shadow-lg p-4 text-base-200"
                />
                <div className="flex flex-row gap-4">
                    <button className="btn bg-white shadow-lg border-none hover:bg-white/40" onClick={() => navigate("/buchhaltung/rechnungen")}>
                        <BILL className="w-6 h-6" />
                        Rechnungen
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => navigate("/buchhaltung/zahlungen")}>
                        <PAYMENT className="w-6 h-6" />
                        Zahlungen
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => {
                            setinvertSort(!invertSort)
                        }}>
                        {invertSort ? <ARROWUP className="w-4 h-4" /> : <ARROWDOWN className="w-4 h-4" />}
                        Sortieren
                    </button>
                    <BaseFilterButton func={() => setshowFilter(!showFilter)} />
                    <BaseFilterModal
                        visible={showFilter}
                        setVisible={() => setshowFilter(!showFilter)}
                        searchdateSince={filterDateSince}
                        changeDateSince={setfilterDateSince}
                        searchdateTo={filterDateTo}
                        changeDateTo={setfilterDateTo}
                        searchStatus={filterStatus}
                        changeStatus={setfilterStatus}
                        statusType={"offer"}
                        removeFilter={handleRemoveFilter}
                        searchArchived={filterArchived}
                        changeArchived={setfilterArchived}
                    />
                </div>
            </section>
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !isLoading && !error && (
                <>
                    {/* TABLE */}
                    <section className="w-full md:px-4 h-[65vh] xl:max-h-[75vh] overflow-x-hidden">
                        <table className="table table-md table-pin-rows bg-white shadow-lg">
                            <thead>
                                <tr className=" h-12 bg-gray-200 border-none">
                                    <th className=" text-base w-12 h-full items-center justify-center"></th>
                                    <th className=" hidden sm:table-cell text-base min-w-[10rem] w-fit text-center">Datum</th>
                                    <th className=" text-base w-fit text-center">{window.innerWidth > 640 ? "Angebotsnummer" : "Nr."}</th>
                                    <th className=" text-base min-w-[8rem] w-fit text-center">Betrag</th>
                                    <th className="hidden md:table-cell text-base w-fit text-center">Kunde</th>
                                    <th className="hidden lg:table-cell text-base w-fit text-center">Objekt</th>
                                    <th className="hidden lg:table-cell text-base min-w-[10rem] w-fit text-center">Status</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-scroll">
                                {displayedOffers?.map((item: OfferGet) => (
                                    <OfferTableRow
                                        key={item._id}
                                        id={item._id}
                                        title={"Angebot " + item.nr}
                                        amount={
                                            item.positions
                                                .reduce((acc: any, curr: any) => {
                                                    return acc + curr.singlePrice * curr.amount
                                                }, 0)
                                                .toFixed(2) + ""
                                        }
                                        customer={item?.customer_id?.customer}
                                        object={item?.object_id}
                                        status={item.status}
                                        date={item.date}
                                        archived={item.archived}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {(!displayedOffers || displayedOffers.length === 0) && (
                            <p className="text-center text-gray-300 uppercase font-medium pt-4">Keine erstellten Angebote</p>
                        )}
                    </section>
                    {/* PAGINATION */}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            defaultCurrent={1}
                            pageSize={15}
                            current={page}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                </>
            )}
            <AddOfferModal queryKey={queryKey}/>
        </BasePage>
    )
}
