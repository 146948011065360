import { API } from "../../../api/constants"
import { authenticatedAxiosInstance } from "../../../auth/AuthenticatedAxios"
import { Customer } from "../types/Types"

export const getAllCustomerData = () => authenticatedAxiosInstance.get(API + `/customer/get/all`).then((res) => res.data)

export const getCustomerObjectData = (id: string) => authenticatedAxiosInstance.get(API + "/customer/object-stats/" + id).then((res) => res.data)

export const getSingleCustomerData = (id: string) => authenticatedAxiosInstance.get(API + "/customer/get/" + id).then((res) => res.data)

export const updateCustomer = (data: { id: string; body: Customer }) =>
    authenticatedAxiosInstance.put(API + "/customer/update/" + data.id, data.body).then((res) => res.data)

export const getAllAdministrator = () => authenticatedAxiosInstance.get(API + "/customer/administrator/get/all").then((res) => res.data)

export const deleteAdministrator = (id: string) => authenticatedAxiosInstance.delete(API + "/customer/administrator/" + id).then((res) => res.data)

// TODO Datatype any
// add Doc to Customer
export const postNewDocToCustomer = (data: { id: string; data: FormData }) =>
    authenticatedAxiosInstance
        .patch(API + "/customer/doc/array/" + data.id, data.data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => res.data)

// delete Doc from single Customer
export const deleteDocFromCustomer = (data: { id: string; s3Key: string }) =>
    authenticatedAxiosInstance.patch(API + "/customer/doc/delete/" + data.id, { file: data.s3Key }).then((res) => res.data)

// get document from customer
export const getDocumentFromCustomer = (data: { id: string; s3Key: string }) =>
    authenticatedAxiosInstance.get(API + `/customer/${data.id}/download/`, { params: { s3Key: data.s3Key } }).then((res) => res.data)
