import { useState } from "react"
import BaseModal from "../layout/BaseModal"
import { BaseAddDocumentModalType } from "../Types"

export default function BaseAddDocumentModal(props: BaseAddDocumentModalType) {
    const [documentcount, setdocumentcount] = useState<number>(1)

    const [documents, setdocuments] = useState([
        {
            fileUpload: File,
        },
    ])
    const [checkForInput, setCheckForInput] = useState([false])

    // add a document to the array
    const addDocument = () => {
        setCheckForInput((prev) => [...prev, false])
        setdocuments((prevDocuments) => [
            ...prevDocuments,
            {
                fileUpload: File,
            },
        ])
    }

    // removes an added document from the array
    const removeDocument = (indexToRemove: number) => {
        setdocuments((prevValues) => prevValues.filter((_, index) => index !== indexToRemove))
    }

    // changes the data of the array, which contains the file data
    const handleInputChange = (index: number, key: string, newValue?: string | File | null) => {
        setdocuments((prevDocuments) => prevDocuments.map((item, idx) => (idx === index ? { ...item, [key]: newValue } : item)))
    }

    // function to add the document(s)
    const handleFileUpload = () => {
        const formData = new FormData()
        documents.forEach((doc, index) => {
            if (doc && doc.fileUpload instanceof File) {
                formData.append("files", doc.fileUpload)
            }
        })
        props.addDoc({
            id: props.selectedId,
            data: formData,
        })
        props.closeModal()
    }

    return (
        <BaseModal
            size
            title={"Dokumente hinzufügen"}
            modalId={props.modalId}
            bottomRow={
                <div className="flex justify-end w-full">
                    <button
                        className="btn btn-success"
                        onClick={() => {
                            if (documents.every((doc) => doc.fileUpload instanceof File && doc.fileUpload.size > 0)) {
                                handleFileUpload()
                            }
                        }}>
                        Speichern
                    </button>
                </div>
            }>
            <p className="text-xs text-gray-400 font-semibold text-center">Maximale Uploadgröße: 10 MB</p>

            {documents.map((item, index) => (
                <div key={index} className="flex flex-row gap-2 items-end pt-2">
                    <div className="flex mt-4 mb-2 items-center gap-2 relative">
                        <input
                            type="file"
                            className="file-input file-input-bordered w-80"
                            required
                            onChange={(e) => {
                                const file = e.target.files && e.target.files[0] && e.target.files[0]
                                handleInputChange(index, "fileUpload", file)
                            }}
                            //   value={item?.file}
                        />
                        {index !== 0 && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-7 h-7 cursor-pointer absolute -top-7 right-0"
                                onClick={() => removeDocument(index)}>
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        )}
                    </div>
                </div>
            ))}
            <div
                className="flex w-full justify-center text-sm items-center pt-2 gap-3 cursor-pointer pb-2"
                onClick={() => {
                    addDocument()
                    setdocumentcount(documentcount + 1)
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p>Weiteres Dokument hinzufügen</p>
            </div>
        </BaseModal>
    )
}
