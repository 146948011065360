import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import { useContext, useState } from "react"
import Transactions from "../components/Transactions/Transactions"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { transactionElementType } from "../types"
import Charts from "../components/Charts/Charts"
import { useQuery } from "@tanstack/react-query"
import { getPaginatedData } from "../../api/apiCalls"
import { useDelayWhenSearchWithPagination } from "../../../services/functions"
import AssignTransactionModal from "../components/Transactions/AssignTransactionModal"
import { Loading } from "react-daisyui"
import ErrorPage from "../../404/ErrorPage"
import { defaultTransactionStatus } from "../../../data/defaultValues"

export default function Dashboard() {
    const modalCon = useContext(ModalContext)
    const [transaction, settransaction] = useState<transactionElementType>()
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const { data, isLoading, error } = useQuery({
        queryKey: ["transactionData" + page + searchParams],
        queryFn: () =>
            getPaginatedData({ pageType: "banking", page: page, search: searchParams, limit: 20, status: defaultTransactionStatus.UNASSIGNED }),
    })
    return (
        <BasePage>
            <HeadBanner title="Dashboard" />
            <div className="flex flex-row gap-4 h-fit w-full">
                <Charts />
                {error && <ErrorPage />}
                {isLoading && <Loading />}
                {data && !error && !isLoading && (
                    <Transactions
                        openModal={() => modalCon?.trigger(1)}
                        func={settransaction}
                        data={data}
                        searchTerm={searchTerm}
                        setsearchTerm={setsearchTerm}
                        page={page}
                        handlePageChange={handlePageChange}
                    />
                )}
            </div>
            <AssignTransactionModal
                modalId={1}
                transaction={transaction}
                transactionReloadLink={"transactionData" + page + searchParams}
                closeModal={() => modalCon?.trigger(1)}
            />
        </BasePage>
    )
}
