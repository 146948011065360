import { useContext } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import { preventKeyDown } from "../../../services/functions"
import { Employee } from "../types/Types"
import EmployeeCard from "./EmployeeCard"
import { useNavigate } from "react-router-dom"
import { Pagination } from "antd"
import ErrorPage from "../../404/ErrorPage"
import Loading from "../../../components/common/Loading"
import BaseFilterModal from "../../../components/elements/BaseFilterModal"
import { ReactComponent as HOUSE } from "../../../assets/HouseICON.svg"

type EmployeePartLeftType = {
    error: Error | null
    isLoading: boolean
    searchTerm: string
    setsearchTerm: (e: string) => void
    showFilter: boolean
    setShowFilter: () => void
    archiveString: boolean | undefined
    setarchiveString: (e: boolean | undefined) => void
    handleFilterRemove: () => void
    positionString: string | undefined
    setpositionString: (e: string | undefined) => void
    displayedEmployee: Employee[]
    selectedEmployee: Employee
    page: number
    handlePageChange: () => void
    totalDocs: number
}

export default function EmployeePartLeft(props: EmployeePartLeftType) {
    const modalCon = useContext(ModalContext)
    const navigate = useNavigate()
    return (
        <div className={`flex flex-col ${props.displayedEmployee.length > 0 ? "sm:w-1/2" : "w-full"} h-80  gap-4 sm:h-auto sm:max-h-full`}>
            <div className="flex flex-row w-full justify-between pr-1.5 relative items-center gap-2">
                <input
                    onKeyDown={preventKeyDown}
                    onChange={(e) => props.setsearchTerm(e.target.value)}
                    type="text"
                    value={props.searchTerm ?? null}
                    placeholder="Mitarbeiter suchen..."
                    className="w-full lg:max-w-xs rounded-default bg-none shadow-lg p-4 text-base-200"
                />
                <div className="flex gap-3 z-40">
                    <button className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40" onClick={() => modalCon?.trigger(9)}>
                        <HOUSE className="w-6 h-6"/>
                        <span className="hidden 2xl:block">Niederlassungen</span>
                    </button>
                    {window.innerWidth > 860 && <BaseFilterButton func={props.setShowFilter} />}
                </div>
                <BaseFilterModal
                    visible={props.showFilter}
                    searchArchived={props.archiveString}
                    changeArchived={props.setarchiveString}
                    removeFilter={props.handleFilterRemove}
                    searchPosition={props.positionString}
                    changePosition={props.setpositionString}
                    setVisible={props.setShowFilter}
                    statusType={"employee"}
                />
            </div>
            {/* Body with Cards */}
            {!props.displayedEmployee || props.displayedEmployee.length === 0 ? (
                <div className="flex flex-col justify-center items-center w-full">
                    <p className="text-gray-300 uppercase font-medium pt-4">Keine Mitarbeiter gefunden</p>
                    <button
                        className="btn btn-ghost mt-2 btn-outline max-w-xs"
                        onClick={() => {
                            props.setsearchTerm("")
                            props.handleFilterRemove()
                        }}>
                        Eingabe zurücksetzen
                    </button>
                </div>
            ) : (
                <>
                    {props.error && <ErrorPage />}
                    {props.isLoading && <Loading />}
                    {!props.error && !props.isLoading && props.displayedEmployee && (
                        <div className="inline-grid w-full grid-cols-1 lg:grid-cols-2 gap-4 overflow-y-scroll max-h-[75vh]">
                            {props.displayedEmployee?.map((item: Employee, index: number) => (
                                <EmployeeCard
                                    setEmployee={() => navigate("/mitarbeiter/" + item._id)}
                                    key={index}
                                    item={item}
                                    selected={(props.selectedEmployee?._id ?? "") === item?._id}
                                    archiveFunc={() => {
                                        item.archived ? modalCon?.trigger(5) : modalCon?.trigger(3)
                                    }}
                                    deleteFunc={function (): void {
                                        modalCon?.trigger(4)
                                    }}
                                />
                            ))}
                        </div>
                    )}
                </>
            )}
            <div className="flex justify-center w-full p-4">
                <Pagination
                    defaultCurrent={1}
                    current={props.page}
                    pageSize={20}
                    onChange={props.handlePageChange}
                    total={props.totalDocs}
                    showSizeChanger={false}
                />
            </div>
        </div>
    )
}
