export const defaultListOfServices = {
    HM1: "wöchentlich",

    HT1: "wöchentlich",
    HT2: "wöchentlich",
    HT3: "wöchentlich",
    HT4: "monatlich",
    HT5: "2x jährlich",
    HT6: "monatlich",

    RA1: "monatlich",
    RA2: "wöchentlich",
    RA3: "wöchentlich",
    RA4: "2x jährlich",
    RA5: "14 tägig",
    RA6: "auf Anforderung",

    RI1: "wöchentlich",
    RI2: "wöchentlich",
    RI3: "wöchentlich",
    RI4: "wöchentlich",
    RI5: "wöchentlich",
    RI6: "wöchentlich",
    RI7: "wöchentlich",
    RI8: "2x jährlich",
    RI9: "2x jährlich",
    RI10: "4x jährlich",
    RI11: "2x jährlich",
    RI12: "monatlich",

    HZ1: "wöchentlich",
    HZ2: "wöchentlich",
    HZ3: "wöchentlich",
    HZ4: "1x jährlich",

    GP1: "wöchentlich",
    GP2: "6x jährlich",
    GP3: "ereignisbezogen",
    GP4: "2x jährlich",
    GP5: "2x jährlich",
    GP6: "nach Bedarf",
    GP7: "nach Bedarf",
    GP8: "zubuchbar",
    GP9: "/",
    GP10: "1x jährlich",

    WD1: "ereignisbezogen",
    WD2: "ereignisbezogen",
    WD3: "ereignisbezogen",

    ND1: "ereignisbezogen",
    ND2: "ereignisbezogen",
    ND3: "ereignisbezogen",
    ND4: "ereignisbezogen",

    SL1: "auf Anforderung",
    SL2: "auf Anforderung",
    SL3: "auf Anforderung",
    SL4: "auf Anforderung",
}

export const defaultPayreqStatusValues = {
    DONE: "settled",
    OVERDUE: "due",
    OPEN: "unpaid",
    CANCELED: "canceled",
}

export const defaultOfferStatusValues = {
    DONE: "settled",
    OPEN: "unanswered",
    CANCELED: "rejected",
}

export const defaultBillCategorie = {
    HAUSMEISTER: "Hausmeister",
    SCHLUESSELDIENST: "Schlüsseldienst",
    BAU: "Bau",
}

export const defaultBillType = {
    NORMAL: "Rechnung",
    YEAR: "Jahresrechnung",
    RECURING: "Dauerrechnung",
}

export const defaultPositions = {
    ADMIN: "Admin",
    BO1: "Backoffice 1",
    BO2: "Backoffice 2",
    BO3: "Backoffice 3",
    MA: "Mitarbeiter",
}

export const defaultTransactionStatus = {
    UNASSIGNED: "UNASSIGNED",
    ASSIGNED: "ASSIGNED",
    PENDING: "PENDING",
    IGNORE: "IGNORED"
}