import { ModalCheckActionType } from "../Types"
import BaseModal from "../layout/BaseModal"

export default function ModalCheckAction(props: ModalCheckActionType) {
    return (
        <BaseModal
            title={props.headline}
            size
            modalId={props.modalId}>
            <p className="max-w-md py-2 px-2">
                {props.text}
            </p>
            {props.additionalCode && props.additionalCode}
            <div className="flex justify-end">
                <button
                disabled={props.disabled}
                    className={`btn ${props.buttonColor}`}
                    onClick={props.func}>
                    {props.buttonText}
                </button>
            </div>
        </BaseModal>
    )
}
