import { useContext, useMemo, useState } from "react"
import BasePage from "../../../components/layout/BasePage"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import CustomerCard from "../components/CustomerCard"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import ModalCheckAction from "../../../components/elements/ModalCheckAction"
import CustomerActionModal from "../components/CustomerActionModal"
import AddCustomerModal from "../components/AddCustomerModal"
import { useNavigate } from "react-router-dom"
import { checkDate, preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"
import { useArchiveDataMutation, useDeleteDataMutation, useRestoreMutation } from "../../api/services"
import BaseFilterModal from "../../../components/elements/BaseFilterModal"
import { messageError, messageInfo } from "../../../services/messages"
import DeleteWithZipModal from "../../../components/elements/DeleteWithZipModal"

export default function Kunden() {
    const navigate = useNavigate()

    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const [filterArchived, setfilterArchived] = useState<boolean | undefined>(false)
    const [filterDelete, setfilterDelete] = useState<boolean>(false)

    // Get Data Query
    const queryKey = "customerData" + page + searchParams + filterArchived + filterDelete
    const { error, data, isLoading } = useQuery({
        queryKey: [queryKey],
        queryFn: () =>
            getPaginatedData({ pageType: "customer", page: page, limit: 20, search: searchParams, archived: filterArchived, deleted: filterDelete }),
    })

    // NOTE -> Filter Variables
    const [showFilter, setShowFilter] = useState(false)
    const modalCon = useContext(ModalContext)

    // reset filter settings
    const handleRemoveFilter = () => {
        setfilterArchived(false)
        setfilterDelete(false)
    }

    const displayedCustomers = useMemo(() => data?.docs, [data?.docs])

    // Archive Customer
    const queryClient = useQueryClient()
    const { mutate: archiveCustomer } = useArchiveDataMutation(queryClient, () => modalCon?.trigger(3), queryKey, "Kundenstatus")
    const [selectedCustomerId, setselectedCustomerId] = useState("")
    const handleArchiveCustomer = (archive: boolean) => {
        archiveCustomer({
            id: selectedCustomerId,
            pageType: "customer",
            body: { archived: archive },
        })
    }

    // Delete Customer
    const { mutate: deleteCustomer } = useDeleteDataMutation(queryClient, modalCon, "Kunde", queryKey)
    const handleDeleteCustomer = () => {
        if (
            displayedCustomers?.find((item: { _id: string }) => item._id === selectedCustomerId).lastSuccessfulZipExport &&
            checkDate(displayedCustomers?.find((item: { _id: string }) => item._id === selectedCustomerId).lastSuccessfulZipExport, 7)
        ) {
            deleteCustomer({ id: selectedCustomerId, pageType: "customer" })
        } else {
            messageError(
                "a1b2c3",
                "Fehler!",
                "Etwas ist schiefgelaufen. Bitte wenden Sie sich mit einer ausführlichen Problembeschreibung an den Administrator."
            )
        }
    }

    // Restore Customer
    const { mutate: restoreCustomer } = useRestoreMutation(queryClient, "Kunden", queryKey)
    const handleRestoreCustomer = (id: string) => {
        restoreCustomer({ id: id, pageType: "customer" })
    }

    return (
        <BasePage>
            <BaseHeadBanner
                title={"Kunden"}
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.kunden_create]}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 cursor-pointer hidden lg:block"
                            onClick={() => {
                                modalCon?.trigger(1)
                            }}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </RenderWhenAuthorized>
                }
            />
            <section className="max-h-[20vh] w-full flex flex-row justify-between gap-4 py-4 md:px-4 items-center relative">
                <input
                    onKeyDown={preventKeyDown}
                    onChange={(e) => setsearchTerm(e.target.value)}
                    type="text"
                    value={searchTerm ?? null}
                    placeholder="Kunden suchen..."
                    className="w-full max-w-xs rounded-default bg-none shadow-lg p-4 text-base-200"
                />
                <BaseFilterButton func={() => setShowFilter(!showFilter)} />
                <BaseFilterModal
                    visible={showFilter}
                    setVisible={() => setShowFilter(false)}
                    searchArchived={filterArchived}
                    changeArchived={setfilterArchived}
                    removeFilter={handleRemoveFilter}
                    statusType={"customer"}
                    searchDeleted={filterDelete}
                    changeDeleted={setfilterDelete}
                />
            </section>
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !isLoading && !error && (
                <>
                    {/* Body with Cards */}
                    <section className=" max-h-[65vh] xl:max-h-[70vh] w-full overflow-y-scroll inline-grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 pb-8 md:px-4">
                        {displayedCustomers?.map((item: any) => (
                            <CustomerCard
                                bills={item.billCount}
                                offers={item.offerCount}
                                objects={item.objectCount}
                                deleted={item.softDeleteAfter}
                                restoreFunc={() => handleRestoreCustomer(item._id)}
                                key={item._id}
                                name={item.customer?.name}
                                owner={item.administrator?.name}
                                adress={item.customer?.adress}
                                archived={item.archived}
                                openFunc={() => {
                                    if (item.softDeleteAfter) {
                                        console.log(item._id)
                                        messageInfo(
                                            "1a2b3c",
                                            "Das klappt gerade nicht!",
                                            'Dieser Kunden ist als "gelöscht" markiert und kann deswegen nicht mehr bearbeitet werden. Stellen Sie den Kunden erst wieder her, bevor zu ihn bearbeiten.',
                                            10
                                        )
                                    } else {
                                        navigate("/kunden/" + item._id)
                                    }
                                }}
                                archiveFunc={() => {
                                    setselectedCustomerId(item._id)
                                    item.archived ? modalCon?.trigger(5) : modalCon?.trigger(3)
                                }}
                                deleteFunc={() => {
                                    if (item.lastSuccessfulZipExport && checkDate(item.lastSuccessfulZipExport, 7)) {
                                        setselectedCustomerId(item._id)
                                        modalCon?.trigger(4)
                                    } else {
                                        setselectedCustomerId(item._id)
                                        modalCon?.trigger(6)
                                    }
                                }}
                            />
                        ))}
                    </section>
                    {(!displayedCustomers || displayedCustomers.length === 0) && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <p className="text-gray-300 uppercase font-medium pt-4">Keine Kunden angelegt</p>
                            <button className="btn btn-ghost mt-2 btn-outline max-w-xs" onClick={() => setsearchTerm("")}>
                                Eingabe zurücksetzen
                            </button>
                        </div>
                    )}
                    <div className="h-[5vh] flex justify-center w-full p-4">
                        <Pagination
                            defaultCurrent={1}
                            pageSize={20}
                            current={page}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                    <AddCustomerModal queryKey={queryKey} />
                    <CustomerActionModal
                        objectAction={() => {
                            modalCon?.trigger(2)
                            modalCon?.trigger(9)
                        }}
                    />
                    <ModalCheckAction
                        modalId={3}
                        headline={"Wirklich Archivieren?"}
                        text={
                            "Sind Sie sich sicher, diesen Kunden archivieren wollen? Er wird nicht mehr in der Liste Ihrer aktiven Kunden angezeigt werden."
                        }
                        buttonText={"Archivieren"}
                        buttonColor="bg-blue-300 border-none hover:bg-blue-400"
                        func={() => handleArchiveCustomer(true)}
                    />
                    <ModalCheckAction
                        modalId={4}
                        headline={"Wirklich Löschen?"}
                        text={
                            "Sind Sie sich sicher, diesen Kunden zu löschen? Alle dazugehörigen Daten werden ebenfalls gelöscht. Der Button wird freigegeben, sobald der ZIP-Export heruntergeladen wurde."
                        }
                        buttonText={"Löschen"}
                        buttonColor="btn-error"
                        func={handleDeleteCustomer}
                        disabled={
                            !displayedCustomers?.find((item: { _id: string }) => item._id === selectedCustomerId)?.zipExportDownloadedBy ||
                            displayedCustomers?.find((item: { _id: string }) => item._id === selectedCustomerId)?.zipExportDownloadedBy?.length ===
                                0 ||
                            !displayedCustomers?.find((item: { _id: string }) => item._id === selectedCustomerId)?.lastSuccessfulZipExport ||
                            !checkDate(
                                displayedCustomers?.find((item: { _id: string }) => item._id === selectedCustomerId)?.lastSuccessfulZipExport,
                                7
                            )
                        }
                    />
                    <ModalCheckAction
                        modalId={5}
                        headline={"Archivieren rückgängig machen?"}
                        text={
                            "Sind Sie sich sicher, diesen Kunden nicht mehr archivieren wollen? Er wird ab sofort wieder in der Liste Ihrer aktiven Kunden angezeigt."
                        }
                        buttonText={"Anzeigen"}
                        buttonColor="bg-blue-300 border-none hover:bg-blue-400"
                        func={() => handleArchiveCustomer(false)}
                    />
                    <DeleteWithZipModal
                        modalId={6}
                        title={"Kunde"}
                        queryKey={queryKey}
                        data={{ id: selectedCustomerId, pageType: "customer" }}
                        closeModal={() => modalCon?.trigger(6)}
                    />
                </>
            )}
        </BasePage>
    )
}
