import BasePage from "../../../components/layout/BasePage"
import HeadBanner from "../../../components/elements/BaseHeadBanner"
import AddObjectModal from "../components/AddObjectModal"
import { useContext, useMemo, useState } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import ObjectCard from "../components/ObjectCard"
import ObjectActionModal from "../components/ObjectActionModal"
import ModalCheckAction from "../../../components/elements/ModalCheckAction"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import { useNavigate } from "react-router-dom"
import { checkDate, preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"
import { useArchiveDataMutation, useDeleteDataMutation, useRestoreMutation } from "../../api/services"
import BaseFilterModal from "../../../components/elements/BaseFilterModal"
import DeleteWithZipModal from "../../../components/elements/DeleteWithZipModal"
import { messageError } from "../../../services/messages"

export default function Objekte() {
    // Variables for Pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // Variables for search in pagination
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)
    const [filterArchived, setfilterArchived] = useState<boolean | undefined>(false)
    const [filterDelete, setfilterDelete] = useState<boolean>(false)

    // Get Data Query
    const queryKey = "objectData" + page + searchParams + filterArchived + filterDelete
    const { error, data, isLoading } = useQuery({
        queryKey: [queryKey],
        queryFn: () =>
            getPaginatedData({ pageType: "object", page: page, limit: 20, search: searchParams, archived: filterArchived, deleted: filterDelete }),
    })

    const navigate = useNavigate()

    // Filter Variables
    const [showFilter, setShowFilter] = useState(false)
    const modalCon = useContext(ModalContext)

    // reset the filter settings
    const handleRemoveFilter = () => {
        setfilterArchived(false)
        setfilterDelete(false)
    }

    // Filter the displayed objects according to filter, user input, usw.
    const displayedObjects = useMemo(() => data?.docs, [data?.docs])

    const queryClient = useQueryClient()
    const [selectedId, setSelectedId] = useState("")

    // Archive Object
    const { mutate: archiveObject } = useArchiveDataMutation(queryClient, () => modalCon?.trigger(3), queryKey, "Objektstatus")
    const handleArchiveObject = (archive: boolean) => {
        archiveObject({
            id: selectedId,
            pageType: "object",
            body: { archived: archive },
        })
    }

    // Delete Object
    const { mutate: deleteObject } = useDeleteDataMutation(queryClient, modalCon, "Objekt", queryKey)
    const handleDeleteObject = () => {
        if (
            displayedObjects?.find((item: { _id: string }) => item._id === selectedId).lastSuccessfulZipExport &&
            checkDate(displayedObjects?.find((item: { _id: string }) => item._id === selectedId).lastSuccessfulZipExport, 7)
        ) {
            deleteObject({ id: selectedId, pageType: "object" })
        } else {
            messageError(
                "a1b2c3",
                "Fehler!",
                "Etwas ist schiefgelaufen. Bitte wenden Sie sich mit einer ausführlichen Problembeschreibung an den Administrator."
            )
        }
    }

    // Restore Object
    const { mutate: restoreObject } = useRestoreMutation(queryClient, "Objekts", queryKey)
    const handleRestoreObject = (id: string) => {
        restoreObject({ id: id, pageType: "object" })
    }

    return (
        <BasePage>
            <HeadBanner
                title={"Objekte"}
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.objekte_create]}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 cursor-pointer hidden lg:block"
                            onClick={() => {
                                modalCon?.trigger(1)
                            }}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </RenderWhenAuthorized>
                }
            />
            <section className="w-full flex flex-row justify-between gap-4 py-4 md:px-4 items-center relative">
                <input
                    onKeyDown={preventKeyDown}
                    onChange={(e) => setsearchTerm(e.target.value)}
                    type="text"
                    value={searchTerm ?? null}
                    placeholder="Objekte suchen..."
                    className="w-full max-w-xs rounded-default bg-none shadow-lg p-4 text-base-200"
                />

                <BaseFilterButton func={() => setShowFilter(!showFilter)} />
                <BaseFilterModal
                    visible={showFilter}
                    setVisible={() => setShowFilter(false)}
                    removeFilter={handleRemoveFilter}
                    statusType={"object"}
                    searchArchived={filterArchived}
                    changeArchived={setfilterArchived}
                    searchDeleted={filterDelete}
                    changeDeleted={setfilterDelete}
                />
            </section>
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !isLoading && !error && (
                <>
                    {/* BODY */}
                    <section className="w-full max-h-[65vh] xl:max-h-[70vh] overflow-y-scroll inline-grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 pb-8 sm:px-4">
                        {displayedObjects?.map((item: any, index: number) => (
                            <ObjectCard
                                key={index}
                                offers={item.offerCount}
                                bills={item.billCount}
                                adress={item.adress}
                                archived={item.archived}
                                deleted={item.softDeleteAfter}
                                openFunc={() => navigate("/objekte/" + item._id)}
                                restoreFunc={() => handleRestoreObject(item._id)}
                                archiveFunc={() => {
                                    setSelectedId(item._id)
                                    item.archived ? modalCon?.trigger(5) : modalCon?.trigger(3)
                                }}
                                deleteFunc={() => {
                                    if (item.lastSuccessfulZipExport && checkDate(item.lastSuccessfulZipExport, 7)) {
                                        setSelectedId(item._id)
                                        modalCon?.trigger(4)
                                    } else {
                                        setSelectedId(item._id)
                                        modalCon?.trigger(6)
                                    }
                                }}
                            />
                        ))}
                    </section>
                    {(!displayedObjects || displayedObjects.length === 0) && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <p className="text-gray-300 uppercase font-medium pt-4">Keine erstellten Objekte</p>
                            {(searchTerm !== "" || searchTerm !== undefined) && (
                                <button className="btn btn-ghost mt-2 btn-outline max-w-xs" onClick={() => setsearchTerm("")}>
                                    Eingabe zurücksetzen
                                </button>
                            )}
                        </div>
                    )}
                    {/* PAGINATION */}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            defaultCurrent={1}
                            pageSize={20}
                            current={page}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                    <AddObjectModal modalId={1} refreshDataUrl={queryKey} />
                    <ObjectActionModal />
                    <ModalCheckAction
                        modalId={3}
                        headline={"Wirklich Archivieren?"}
                        text={
                            "Sind Sie sich sicher, dieses Objekt archivieren wollen? Es wird nicht mehr in der Liste Ihrer aktiven Objekte angezeigt werden."
                        }
                        buttonText={"Archivieren"}
                        buttonColor="bg-blue-300 border-none hover:bg-blue-400"
                        func={() => handleArchiveObject(true)}
                    />
                    <ModalCheckAction
                        modalId={4}
                        headline={"Wirklich Löschen?"}
                        text={
                            "Sind Sie sich sicher, dieses Objekt zu löschen? Alle dazugehörigen Daten werden ebenfalls gelöscht. Der Button wird freigegeben, sobald der ZIP-Export heruntergeladen wurde"
                        }
                        buttonText={"Löschen"}
                        buttonColor="btn-error"
                        func={() => handleDeleteObject()}
                        disabled={
                            !displayedObjects?.find((item: { _id: string }) => item._id === selectedId)?.zipExportDownloadedBy ||
                            displayedObjects?.find((item: { _id: string }) => item._id === selectedId)?.zipExportDownloadedBy?.length === 0 ||
                            !displayedObjects?.find((item: { _id: string }) => item._id === selectedId)?.lastSuccessfulZipExport ||
                            !checkDate(displayedObjects?.find((item: { _id: string }) => item._id === selectedId)?.lastSuccessfulZipExport, 7)
                        }
                    />
                    <ModalCheckAction
                        modalId={5}
                        headline={"Archivieren rückgängig machen?"}
                        text={
                            "Sind Sie sich sicher, dieses Objekt nicht mehr archivieren wollen? Es wird ab sofort wieder in der Liste Ihrer aktiven Objekte angezeigt werden."
                        }
                        buttonText={"Anzeigen"}
                        buttonColor="bg-blue-300 border-none hover:bg-blue-400"
                        func={() => handleArchiveObject(false)}
                    />
                    <DeleteWithZipModal
                        modalId={6}
                        title={"Objekt"}
                        queryKey={queryKey}
                        data={{ id: selectedId, pageType: "object" }}
                        closeModal={() => modalCon?.trigger(6)}
                    />
                </>
            )}
        </BasePage>
    )
}
